import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa'; // Importing the spinner icon

const CreateSlotForm = ({ onClose, onSubmit, onEdit, cityList, error, slotData = null }) => {
  const [name, setName] = useState(slotData?.name || '');
  const [mobileNumber, setMobileNumber] = useState(slotData?.mobileNumber || '');
  const [address, setAddress] = useState(slotData?.address || '');
  const [locationURL, setLocationURL] = useState(slotData?.locationUrl || '');
  const [pincode, setPincode] = useState(slotData?.pincode || '');
  const [day, setDay] = useState(slotData?.schedule || '');
  const [city, setCity] = useState(slotData?.cityId || '');
  const [startTime, setStartTime] = useState(slotData?.startTime || '');
  const [toTime, setToTime] = useState(slotData?.endTime || '');
  const [loading, setLoading] = useState(false); // Track loading state

  const dayList = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 7, name: 'Sunday' },
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (slotData?.id) {
      onEdit({ id: slotData.id, name, mobileNumber, address, locationURL, pincode, city, schedule: day, startTime, toTime });
    } else {
      onSubmit({ name, mobileNumber, address, locationURL, pincode, city, schedule: day, startTime, toTime });
    }
    setLoading(false);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>

        <form onSubmit={handleFormSubmit} className="bg-white p-6 rounded-md shadow-lg w-full">
          {/* Error Message */}
          {error && (
            <div className="text-sm text-red-500 mb-4 text-center">
              {error}
            </div>
          )}

          <h2 className="text-lg font-semibold mb-4">
            {slotData ? 'Edit Slot' : 'Create New Slot'}
          </h2>

          {/* Name Field */}
          <div className="flex items-center mb-4">
            <label className="w-1/3 mb-2" htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mb-4 p-2 border rounded w-2/3"
              required
            />
          </div>

          {/* Mobile Number */}
          <div className="flex items-center mb-4">
            <label className="w-1/3 mb-2" htmlFor="mobileNumber">Mobile Number:</label>
            <input
              type="text"
              id="mobileNumber"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              className="mb-4 p-2 border rounded w-2/3"
              required
            />
          </div>

          {/* Address */}
          <div className="flex items-center mb-4">
            <label className="w-1/3 mb-2" htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="mb-4 p-2 border rounded w-2/3"
              required
            />
          </div>

          {/* Location URL */}
          <div className="flex items-center mb-4">
            <label className="w-1/3 mb-2" htmlFor="locationURL">Location URL:</label>
            <input
              type="url"
              id="locationURL"
              value={locationURL}
              onChange={(e) => setLocationURL(e.target.value)}
              className="mb-4 p-2 border rounded w-2/3"
              required
            />
          </div>

          {/* Pincode */}
          <div className="flex items-center mb-4">
            <label className="w-1/3 mb-2" htmlFor="pincode">Pincode:</label>
            <input
              type="text"
              id="pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              className="mb-4 p-2 border rounded w-2/3"
              required
            />
          </div>

          {/* City Selection */}
          <div className="flex items-center mb-4">
            <label className="w-1/3 mb-2" htmlFor="city">City:</label>
            <select
              id="city"
              value={city}
              onChange={handleCityChange}
              className="mb-4 p-2 border rounded w-2/3"
            >
              <option value="">Select City</option>
              {cityList.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          {/* Day Selection */}
          <div className="flex items-center mb-4">
            <label className="w-1/3 mb-2" htmlFor="day">Day:</label>
            <select
              id="day"
              value={day}
              onChange={(e) => setDay(e.target.value)}
              className="mb-4 p-2 border rounded w-2/3"
            >
              <option value="">Select Day</option>
              {dayList.map(({ id, name }) => (
                <option key={id} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          {/* From Time */}
          <div className="flex items-center mb-4">
            <label className="w-1/3 mb-2" htmlFor="startTime">From Time:</label>
            <input
              type="time"
              id="startTime"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className="mb-4 p-2 border rounded w-2/3"
              required
            />
          </div>

          {/* To Time */}
          <div className="flex items-center mb-4">
            <label className="w-1/3 mb-2" htmlFor="toTime">To Time:</label>
            <input
              type="time"
              id="toTime"
              value={toTime}
              onChange={(e) => setToTime(e.target.value)}
              className="mb-4 p-2 border rounded w-2/3"
              required
            />
          </div>

          {/* Action Buttons */}
          <div className="flex justify-center space-x-4 mt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            >
              Cancel
            </button>

            <button
              type="submit"
              className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${loading ? 'bg-blue-300 cursor-not-allowed' : ''}`}
              disabled={loading} // Disable the button when loading
            >
              {loading ? (
                <FaSpinner className="animate-spin mr-2" />
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateSlotForm;
