import React, { useEffect, useState } from 'react';
import Header from "./Header";
import verifiedIcon from '../assets/verified_icon.png';
import { FaShareSquare, FaCheck } from 'react-icons/fa';


const ProfileCard = ({ userId }) => {
  const [profilePic, setProfilePic] = useState('');
  const [rating, setRating] = useState(0.0);
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [verified, setVerified] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUserName, setLoggedInUserName] = useState('')
  const [loggedInUserProfilePic, setLoggedInUserProfilePic] = useState('')
  const [copied, setCopied] = useState(false);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <span className="text-yellow-500 text-xs">
        {'★'.repeat(fullStars)}
        {'✩'.repeat(halfStars)}
        {'☆'.repeat(emptyStars)}
      </span>
    );
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`/v1/profile/${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setProfilePic(data.profilePic);
        setName(data.name);
        setGender(data.gender);
        setAboutMe(data.aboutMe);
        setSpeciality(data.speciality);
        setVerified(data.verified);
        setRating(data.rating);
        setIsLoggedIn(data.isLoggedIn);
        setLoggedInUserName(data.loggedInUserName)
        setLoggedInUserProfilePic(data.loggedInUserProfilePic)
      } catch (error) {
        window.location.href = '/';
      }
    };

    fetchUserData();
  }, [userId]);

  const handleLogout = async (e) => {
    e.preventDefault();
    const response = await fetch('/v1/logout');
    const data = response.json();
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    localStorage.removeItem('jwt');
    localStorage.removeItem('profilePic');

    setIsLoggedIn(false);
    setLoggedInUserName('');
    setLoggedInUserProfilePic('');
  };

  const handleSlotBooking = () => {
    window.location.href = `/slots/${userId}`;
  };

  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      })
      .catch((error) => error);
  };

  return (
    <div>
      <Header 
        profilePicture={loggedInUserProfilePic} 
        profileName={loggedInUserName} 
        isLoggedIn={isLoggedIn} 
        handleLogout={handleLogout}
      />
      <div className="pt-20 pb-2 pl-2 pr-2 bg-gray-100 flex items-center justify-center min-h-screen">
        <div
          className="w-full max-w-xl flex flex-col items-center bg-white p-8 rounded-lg shadow-md transition-transform duration-300 ease-in-out relative"
          style={{ boxShadow: 'none' }}
        >
          {/* Verified Badge */}
          {verified && (
            <div className="absolute top-4 right-4">
              <div className="group relative">
                {/* Verified Badge Icon */}
                <img
                  src={verifiedIcon}
                  alt="Verified Badge"
                  className="w-5 h-5"
                  title="Verified by BookMySlots.in"
                />
                {/* Tooltip */}
                <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-black text-white text-xs rounded-lg py-1 px-2 z-10">
                  Verified by BookMySlots.in
                </div>
              </div>
            </div>
          )}
          {/* Share Icon */}
          <div className="absolute top-4 right-12 cursor-pointer text-gray-500" onClick={handleShare}>
            <FaShareSquare size={23} />
          </div>

          {/* Show copied message */}
          {copied && (
            <div className="absolute top-4 right-20 flex items-center space-x-1 text-green-500">
              <FaCheck />
              <span>Profile copied</span>
            </div>
          )}

          {/* Small Text: "BookMySlots.in Profile" */}
          <div className="w-full text-center mb-6 text-sm text-gray-600">
            <span>Profile for <strong>BookMySlots.in</strong></span>
          </div>

          {/* Profile Picture */}
          <div className="mb-3 flex flex-col items-center">
            <img
              src={`data:image/jpeg;base64,${profilePic}`}
              alt="Profile"
              className="w-36 h-36 rounded-full border-2 border-gray-300 mb-4"
            />
          </div>

          {/* {Rating}
          <div className="mb-4 w-full text-center">
            <div className="flex justify-center space-x-1">
              {renderStars(rating)}
              <span className="ml-1 text-gray-600 text-xs">({rating.toFixed(1)})</span>
            </div>
          </div> */}

          {/* Name */}
          <div className="mb-4 w-full text-center">
            <span className="font-semibold">Name:</span>
            <div className="p-2 border border-gray-300 rounded w-full bg-gray-200">{name}</div>
          </div>

          {/* About Me */}
          <div className="mb-4 w-full text-center">
            <span className="font-semibold">About Me:</span>
            <div className="p-2 border border-gray-300 rounded w-full bg-gray-200">{aboutMe}</div>
          </div>

          {/* Speciality with wrapping */}
          <div className="mb-4 w-full text-center">
            <span className="font-semibold">Specialities:</span>
            <div className="text-sm whitespace-normal break-words p-2 border border-gray-300 rounded w-full bg-gray-200">{speciality}</div>
          </div>

          {/* Gender */}
          <div className="mb-4 w-full text-center">
            <label className="block font-semibold mb-2">Gender:</label>
            <div className="flex justify-center space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  checked={gender === 'male'}
                  readOnly
                  className="mr-1"
                />
                Male
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={gender === 'female'}
                  readOnly
                  className="mr-1"
                />
                Female
              </label>
            </div>
          </div>

          {/* Book Appointment Button */}
          <button 
            onClick={handleSlotBooking}
            className="bg-green-500 text-white px-3 py-1 h-12 rounded-md hover:bg-green-600 transition duration-200 w-full flex items-center justify-center shadow-md transform transition-transform duration-150 ease-in-out active:scale-95">
            Book an Appointment
            <span className="ml-2">→</span>
          </button>

        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
