import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white shadow-md text-black py-6 mt-auto">
      <div className="container mx-auto flex flex-col items-center">
        <p className="mb-4 text-sm">
          © {new Date().getFullYear()} BookMySlot.in. All rights reserved.
        </p>
        <div className="flex space-x-6">
          <Link
            to="/about"
            className="text-sm hover:text-blue-400 transition duration-200"
          >
            About
          </Link>
          <Link
            to="/blogs"
            className="text-sm hover:text-blue-400 transition duration-200"
          >
            Blogs
          </Link>
          <Link
            to="/contact"
            className="text-sm hover:text-blue-400 transition duration-200"
          >
            Contact
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
