import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    setError('');
    setIsLoading(true);

    try {
      const response = await fetch('/v1/authn/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include',
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error);
        return;
      }

      if (data.profilePic) {
        localStorage.setItem('profilePic', data.profilePic);
      }

      if (data.otp === 'pending') {
        navigate('/otp', { state: { next: '/' } });
        return;
      }

      window.location.href = '/';
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <form
          className="flex flex-col bg-white p-6 rounded shadow-md w-full max-w-sm"
          onSubmit={onSubmitHandler}
        >
          <p className="mb-4 text-sm text-gray-600 text-center">
            Welcome back to <span className="font-semibold text-gray-800">BookMySlots.in</span>
          </p>
          <h2 className="mb-4 text-xl font-semibold text-center">Login</h2>
          
          {error && (
            <div className="flex justify-center mb-4">
              <p className="text-red-500">{error}</p>
            </div>
          )}
          
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-4 p-2 border border-gray-300 rounded"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mb-4 p-2 border border-gray-300 rounded"
            required
          />

          <button
            type="submit"
            disabled={isLoading} // Disable button when loading
            className={`p-2 rounded transition duration-200 ${
              isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <svg
                  className="w-4 h-4 mr-2 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                  ></path>
                </svg>
                Logging in...
              </span>
            ) : (
              'Login'
            )}
          </button>

          <p className="mt-6 text-center">
            Don’t have an account?{' '}
            <Link to="/signup" className="text-blue-500 hover:underline">
              Signup
            </Link>
          </p>
          <p className="mt-1 text-center">
            Forgot your password?{' '}
            <Link to="/forget-password" className="text-blue-500 hover:underline">
              Reset password
            </Link>
          </p>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
