import React, { useState } from 'react';
import { FaCopy, FaCheck } from 'react-icons/fa';

const SlotCard = ({
  id,
  doctorName,
  address,
  city,
  pincode,
  locationLink,
  mobileNumber,
  fromTime,
  toTime
}) => {
  const [copyStatus, setCopyStatus] = useState('');

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopyStatus('Copied');
    setTimeout(() => setCopyStatus(''), 3000);
  };

  return (
    <div
      key={id}
      className="p-4 mb-4 border border-gray-300 rounded-lg shadow-lg bg-white hover:shadow-xl transition max-w-lg mx-auto"
    >
      <p className="text-base">
        <span className="font-bold mr-2">Doctor:</span> {doctorName}
      </p>
      <div className="text-base">
        <div className="flex items-center">
          <label htmlFor="mobileNumber" className="font-semibold">Mobile Number:</label>
          <div className="ml-2 flex items-center">
            <span id="mobileNumber" className="mr-2">
              {mobileNumber}
            </span>
            {copyStatus ? (
              <div className="text-xs text-black flex items-center ml-2">
                <FaCheck className="mr-1 text-black" /> <span>{copyStatus}</span>
              </div>
            ) : (<button
              type="button"
              onClick={() => handleCopy(mobileNumber)}
              className="ml-2 bg-gray-200 rounded hover:bg-gray-300 transition cursor-pointer"
            >
              <FaCopy />
            </button>)}
          </div>
        </div>
      </div>

      <p className="text-base">
        <span className="font-bold mr-2">Address:</span> {address}
      </p>
      <p className="text-base">
        <span className="font-bold mr-2">City:</span> {city}
      </p>
      <p className="text-base">
        <span className="font-bold mr-2">Pincode:</span> {pincode}
      </p>
      <p className="text-base">
        <span className="font-bold mr-2">Time:</span> {fromTime} - {toTime}
      </p>

      {locationLink && (
        <p className="text-base">
          <span className="font-bold mr-2">Location:</span>
          <a
            href={locationLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 text-base underline"
          >
            View Location
          </a>
        </p>
      )}
    </div>
  );
};

export default SlotCard;
