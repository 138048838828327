import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/bookmyslots-logo.png';
import raashidPicture from '../assets/raashidanwar.jpg';
import Footer from '../components/Footer';
import Header from '../components/Header';

const About = () => {
  return (
    <div className="about-container flex flex-col min-h-screen">
      <Header hideLogin={true} />
      {/* Main content */}
      <div className="flex flex-col min-h-[33vh] justify-between items-center p-4 mt-20 sm:mt-16">
        {/* Make the logo clickable */}
        <Link to="/" className="mb-4">
          <img src={logo} alt="Bookmyslots Logo" className="w-32 h-auto" /> {/* Logo added */}
        </Link>
        <p className="text-xl">
          Welcome to <span className="font-bold italic">BookMySlots.in</span>
        </p>
        <h2 className="text-2xl font-bold mt-4">About Us</h2>
        
        {/* About Content Box */}
        <div className="max-w-4xl w-full bg-white p-6 rounded-lg shadow-md mt-8">
          <h1 className="text-3xl font-semibold text-center mb-6 text-gray-800">
            About <span className="font-bold italic">BookMySlots.in</span>
          </h1>
          
          <p className="text-lg text-gray-700 mb-4">
            <span className="font-bold italic">BookMySlots.in</span> is a revolutionary platform designed to simplify the process of booking doctor's appointments in India. 
            We aim to streamline the entire appointment process by allowing users to easily search for doctors, view available slots, 
            and book appointments with just a click of a button.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Our Goal</h2>
          <p className="text-lg text-gray-700 mb-4">
            The goal of this project is to create a comprehensive doctor registry where people can search for doctors based on various filters such as specialization, location, and availability. 
            By eliminating the need for long phone calls and back-and-forth communication with doctor's secretaries, 
            we aim to make the entire process of booking an appointment as seamless and hassle-free as possible.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">For Patients</h2>
          <p className="text-lg text-gray-700 mb-4">
            Traditionally, booking an appointment with a doctor in India involves asking relatives about the doctor's availability in various locations, 
            followed by calling the secretary to confirm and schedule the appointment. This process can be cumbersome, time-consuming, and frustrating.
          </p>
          <p className="text-lg text-gray-700 mb-4">
            <span className="font-bold italic">BookMySlots.in</span> seeks to reduce these efforts significantly. Patients can now search for their preferred doctor, 
            check available time slots, and book an appointment with just a few clicks—anytime, anywhere.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">For Doctors</h2>
          <p className="text-lg text-gray-700 mb-4">
            For doctors, managing appointments can be a tedious task, especially when adjusting schedules and updating available time slots. 
            <span className="font-bold italic"> BookMySlots.in</span> simplifies this process by providing doctors with an easy-to-use interface to add, modify, or remove available slots. 
            Once updated, the changes are instantly reflected for patients to see, ensuring that both doctors and patients are always in sync.
          </p>

          <p className="text-lg text-gray-700 mt-6">
            At <span className="font-bold italic">BookMySlots.in</span>, we believe in leveraging technology to improve the healthcare experience. 
            Our platform aims to provide an efficient, transparent, and user-friendly experience for both patients and doctors alike.
          </p>
        </div>

        {/* Team Section */}
        <div className="max-w-4xl w-full bg-white p-6 rounded-lg shadow-md mt-8">
          <h2 className="text-3xl font-semibold text-center mb-6 text-gray-800">Meet the Team</h2>
          <div className="flex flex-col items-center text-center">
            {/* Team member info */}
            <img 
              src={raashidPicture} 
              alt="Raashid Anwar" 
              className="rounded-full w-32 h-32 mb-4"
            />
            <p className="text-xl font-semibold mb-2">Hey 👋, My Name is Raashid Anwar</p>
            <p className="text-lg text-gray-700 mb-4">
              I am a Software Engineer at Google, and I am building <span className="font-bold italic">BookMySlots.in</span> as a side project.
            </p>
            
            {/* LinkedIn and Email */}
            <div className="flex justify-center space-x-4 mt-6">
              {/* LinkedIn link */}
              <a 
                href="https://www.linkedin.com/in/raashidanwar" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 font-semibold"
              >
                LinkedIn
              </a>

              {/* Vertical Line */}
              <span className="border-l-2 border-gray-400 h-6 mx-4"></span>

              {/* Personal Website link */}
              <a 
                href="https://raashidanwar.in" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 font-semibold"
              >
                raashidanwar.in
              </a>

              {/* Vertical Line */}
              <span className="border-l-2 border-gray-400 h-6 mx-4"></span>

              {/* Email link */}
              <a 
                href="mailto:raashid12anwar@gmail.com" 
                className="text-blue-600 hover:text-blue-800 font-semibold"
              >
                Email
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer /> {/* Make sure Footer spans full width */}
    </div>
  );
};

export default About;
