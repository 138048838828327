import React, { useState } from 'react';

const SearchFilter = ({ 
  cityList, 
  specialityList, 
  city, 
  setCity, 
  speciality, 
  setSpeciality, 
  pincode, 
  setPincode,
  verified,
  setVerified,
}) => {
	const handleCityChange = (e) => {
		const selectedCity = cityList.find(city => city.name.replace(/\s+/g, '-') === e.target.value);
		if (selectedCity) {
			setCity(selectedCity.id);
		} else {
			setCity('');
		}
	};

	const handleSpecialityChange = (e) => {
		const selectedSpeciality = specialityList.find(speciality => speciality.name === e.target.value);
		if (selectedSpeciality) {
			setSpeciality(selectedSpeciality.id);
		} else {
			setSpeciality('');
		}
	};

	const validatePincode = (value) => {
		const regex = /^[1-9][0-9]{5}$/;
		return regex.test(value);
	};

	const handleChange = (e) => {
		const value = e.target.value;

		// Allow only digits and check if it matches the validation criteria
		if (/^\d*$/.test(value) && (value.length <= 6)) { // Allow only digits and limit length to 6
			setPincode(value);
		}
	};

	// Handle checkbox change for Verified Profile
	const handleCheckboxChange = (e) => {
		setVerified(e.target.checked);
	};

	return (
		<div className="m-5">
			<div className="flex flex-wrap gap-4 mb-4 items-center">
				{/* Speciality */}
				<select 
					className="flex-1 min-w-[150px] p-2 border rounded-md" 
					value={speciality.name} 
					onChange={handleSpecialityChange}
				>
					<option value="">Speciality</option>
					{specialityList.map(({ id, name }) => (
						<option key={id} value={name}>
							{name}
						</option>
					))}
				</select>

				{/* City */}
				<select 
					className="flex-1 min-w-[150px] p-2 border rounded-md" 
					value={city.name} 
					onChange={handleCityChange}
				>
					<option value="">City</option>
					{cityList.map(({ id, name }) => (
						<option key={id} value={name.replace(/\s+/g, '-')}>
							{name}
						</option>
					))}
				</select>

				{/* Pincode */}
				<input 
					type="text" 
					className={`flex-1 min-w-[150px] p-2 border rounded-md ${pincode === '' ? '' : validatePincode(pincode) ? 'border-green-500' : 'border-red-500'}`} 
					placeholder="Pincode" 
					value={pincode} 
					onChange={handleChange} 
				/>

				{/* Verified Profile Checkbox */}
				<label className="flex-1 min-w-[150px] p-2 rounded-md">
					<input 
						type="checkbox" 
						checked={verified}
						onChange={handleCheckboxChange}
						className="scale-150 mr-2" // Increased size
					/>
					Verified Profile
				</label>
			</div>
		</div>
  );
};

export default SearchFilter;
