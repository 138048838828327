import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/bookmyslots-logo.png'; // Import the logo
import Footer from '../components/Footer';
import Header from '../components/Header'; // Import Header if needed

const Contact = () => {
  return (
    <div className="contact-container flex flex-col min-h-screen">
      <Header hideLogin={true} />
      {/* Main content */}
      <div className="flex flex-col min-h-[33vh] justify-center items-center p-4 mt-20 sm:mt-16">
        {/* Logo with link */}
        <Link to="/" className="mb-4">
          <img
            src={logo}
            alt="Bookmyslots Logo"
            className="w-32 h-auto"
          />
        </Link>
        <p className="text-xl">
          Welcome to <span className="font-bold italic">BookMySlots.in</span>
        </p>
        <h2 className="text-2xl font-bold mt-4">Contact Us</h2>

        {/* Contact Information */}
        <div className="max-w-4xl w-full bg-white p-6 rounded-lg shadow-md mt-8 text-center">
          <p className="text-lg text-gray-700 mb-4">
            Want to know more? Reach out to us at{' '}
            <a
              href="mailto:info.bookmyslots@gmail.com"
              className="font-bold text-blue-500 hover:text-blue-700"
            >
              info.bookmyslots@gmail.com
            </a>
          </p>
        </div>
      </div>

      {/* Footer */}
      <Footer /> {/* Make sure Footer spans full width */}
    </div>
  );
};

export default Contact;
