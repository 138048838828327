import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

const ResetPassword = () => {
  const { resetId } = useParams(); // Get resetId from URL params
  const navigate = useNavigate();

  // State for password fields and status messages
  const [password, setPassword] = useState('');
  const [passwordRetype, setPasswordRetype] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResetIdValid, setIsResetIdValid] = useState(false);

  // Validate resetId on component mount
  useEffect(() => {
    const validateResetId = async () => {
      try {
        const response = await fetch(`/v1/authn/reset-password/${resetId}`, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Invalid or expired reset link');
        }

        setIsResetIdValid(true); // Reset link is valid
      } catch (err) {
        setError(err.message || 'Failed to validate reset link');
        setIsResetIdValid(false); // Invalid reset link
      }
    };

    validateResetId();
  }, [resetId]);

  // Handle form submission
  const onSubmitHandler = async (e) => {
    e.preventDefault(); // Prevent default form submission

    if (password !== passwordRetype) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true); // Start loading

    try {
      const response = await fetch('/v1/authn/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resetId, password, passwordRetype }),
        credentials: 'include',
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'Failed to reset password');
        return;
      }

      setSuccess('Your password has been updated successfully!');
      setError('');
      setPassword('');
      setPasswordRetype('');

      // Redirect to login after a delay
      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      setError(err.message || 'Something went wrong');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        {isResetIdValid ? (
          <form
            className="flex flex-col bg-white p-6 rounded shadow-md w-full max-w-sm"
            onSubmit={onSubmitHandler}
          >
            <p className="mb-4 text-sm text-gray-600 text-center">
              Update your <span className="font-semibold text-gray-800">BookMySlots.in</span> password
            </p>
            <h2 className="mb-4 text-xl font-semibold text-center">Reset Your Password</h2>

            {error && (
              <div className="flex justify-center mb-4">
                <p className="text-red-500">{error}</p>
              </div>
            )}
            {success && (
              <div className="flex justify-center mb-4">
                <p className="text-green-500">{success}</p>
              </div>
            )}

            <input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mb-4 p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="password"
              placeholder="Retype New Password"
              value={passwordRetype}
              onChange={(e) => setPasswordRetype(e.target.value)}
              className="mb-4 p-2 border border-gray-300 rounded"
              required
            />

            <button
              type="submit"
              disabled={isLoading}
              className={`p-2 rounded transition duration-200 ${
                isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
              } text-white`}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="w-4 h-4 mr-2 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                    ></path>
                  </svg>
                  Processing...
                </span>
              ) : (
                'Reset Password'
              )}
            </button>
          </form>
        ) : (
          <p className="text-center text-red-500">
            {error || 'Validating reset link...'}
          </p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
