import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');
  const [gender, setGender] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State for button loading
  const navigate = useNavigate();

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (!name || !email || !password || !type || !gender) {
      setError('All fields are required!');
      return;
    }

    setError('');
    setIsLoading(true); // Set loading to true

    try {
      const response = await fetch('/v1/authn/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password, type, gender }),
      });

      const data = await response.json();
      if (!response.ok) {
        setError(data.error);
        return;
      }

      if (data.profilePic) {
        localStorage.setItem('profilePic', data.profilePic);
      }

      if (data.otp === 'pending') {
        const next = type === 'dotor' ? '/profile' : '/'
        navigate(`/otp?next=${next}`);
        return;
      }

      window.location.href = (type === 'doctor') ? '/profile' : '/';
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false); // Set loading to false
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <form
          className="flex flex-col bg-white p-6 rounded shadow-md w-full max-w-sm"
          onSubmit={onSubmitHandler}
        >
          <p className="mb-4 text-sm text-gray-600 text-center">
            Create an account on <span className="font-semibold text-gray-800">BookMySlots.in</span>
          </p>
          <h2 className="mb-4 text-xl font-semibold text-center">Signup</h2>

          {error && (
            <div className="flex justify-center mb-4">
              <p className="text-red-500">{error}</p>
            </div>
          )}

          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mb-4 p-2 border border-gray-300 rounded"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-4 p-2 border border-gray-300 rounded"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mb-4 p-2 border border-gray-300 rounded"
          />

          {/* User Type Selection */}
          <div className="mb-4">
            <label className="block mb-2 font-semibold">I am a:</label>
            <div className="flex justify-between">
              <button
                type="button"
                className={`w-1/2 p-2 border ${
                  type === 'user' ? 'bg-green-500 text-white' : 'bg-gray-100'
                } rounded mr-2`}
                onClick={() => setType('user')}
              >
                User
              </button>
              <button
                type="button"
                className={`w-1/2 p-2 border ${
                  type === 'doctor' ? 'bg-green-500 text-white' : 'bg-gray-100'
                } rounded`}
                onClick={() => setType('doctor')}
              >
                Doctor
              </button>
            </div>
          </div>

          {/* Gender Selection */}
          <div className="mb-4">
            <label className="block mb-2 font-semibold">Gender:</label>
            <div className="flex justify-between">
              <button
                type="button"
                className={`w-1/2 p-2 border ${
                  gender === 'male' ? 'bg-green-500 text-white' : 'bg-gray-100'
                } rounded mr-2`}
                onClick={() => setGender('male')}
              >
                Male
              </button>
              <button
                type="button"
                className={`w-1/2 p-2 border ${
                  gender === 'female' ? 'bg-green-500 text-white' : 'bg-gray-100'
                } rounded`}
                onClick={() => setGender('female')}
              >
                Female
              </button>
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading} // Disable button when loading
            className={`p-2 rounded transition duration-200 ${
              isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <svg
                  className="w-4 h-4 mr-2 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                  ></path>
                </svg>
                Sending...
              </span>
            ) : (
              'Signup'
            )}
          </button>

          <p className="mt-4 text-center">
            Already have an account?{' '}
            <Link to="/login" className="text-blue-500 hover:underline">
              Login
            </Link>
          </p>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
