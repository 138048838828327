import React, { useState } from 'react';
import logo from '../assets/bookmyslots-logo.png';
import navLogo from '../assets/nav-logo.png';

const Header = ({ profileName, profilePicture, isLoggedIn, logoutHandler, hideLogin = false }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu((prev) => !prev);
  };

  const navigateTo = (path) => {
    window.location = path;
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    logoutHandler();
  };

  const handleLogin = () => {
    window.location = '/login';
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white shadow-md p-4 z-50 flex justify-between items-center">
      <div className="flex items-center space-x-4">
        {/* Nav Logo with Dropdown */}
        <div className="relative">
          <button
            onClick={toggleMenu}
            className="flex items-center bg-white border border-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-100 transition duration-200"
          >
            <img src={navLogo} alt="Navigation" className="w-8 h-8 rounded-full" />
          </button>
          {showMenu && (
            <div className="absolute top-full left-0 mt-2 bg-white shadow-lg rounded-lg z-50 border p-2">
              <ul className="flex flex-col space-y-2">
                <li>
                  <button
                    onClick={() => navigateTo('/')}
                    className="flex items-center space-x-3 px-4 py-2 w-full text-left hover:bg-gray-100 rounded-md"
                  >
                    <img src={logo} alt="Home Logo" className="w-6 h-6 -ml-1" /> {/* Added -ml-1 */}
                    <span>Home</span>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => navigateTo('/search')}
                    className="flex items-center space-x-3 px-4 py-2 w-full text-left hover:bg-gray-100 rounded-md"
                  >
                    <span role="img" aria-label="search" className="text-lg">🔍</span>
                    <span>Search</span>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>


      {hideLogin ? <div></div> : (isLoggedIn ? (
        <div className="relative">
          <button
            onClick={toggleProfileMenu}
            className="flex items-center bg-white border border-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-100 transition duration-200"
          >
            <img
              src={
                profilePicture
                  ? `data:image/jpeg;base64,${profilePicture}`
                  : '../../assets/dummy-male.jpg'
              }
              className="w-8 h-8 rounded-full mr-2"
              alt={profileName}
            />
            <svg
              className="w-4 h-4 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          {showProfileMenu && (
            <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-md z-50 border p-2">
              <ul className="flex flex-col space-y-2">
                <li className="flex items-center px-4 py-2 hover:bg-gray-100 rounded-md whitespace-nowrap">
                  <span role="img" aria-label="profile" className="text-lg">👤</span>
                  <span className="ml-3">{profileName}</span>
                </li>
                <li className="flex items-center px-4 py-2 hover:bg-gray-100 rounded-md whitespace-nowrap">
                  <span role="img" aria-label="settings" className="text-lg">⚙️</span>
                  <button className="ml-3" onClick={() => navigateTo('/profile')}>Account</button>
                </li>
                <li className="flex items-center px-4 py-2 hover:bg-gray-100 rounded-md text-red-500 whitespace-nowrap">
                  <span role="img" aria-label="logout" className="text-lg">🚪</span>
                  <button className="ml-3" onClick={handleLogout}>Sign Out</button>
                </li>
              </ul>
            </div>
          )}
        </div>
      ) : (
        <button
          onClick={handleLogin}
          className="ml-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-200"
        >
          Login
        </button>
      ))}
    </header>
  );
};

export default Header;
