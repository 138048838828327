import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SlotCard from '../components/SlotCard';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Slots = () => {
  const { userId } = useParams();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUserName, setLoggedInUserName] = useState('')
  const [loggedInUserProfilePic, setLoggedInUserProfilePic] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [doctorName, setDoctorName] = useState('');
  const [slotList, setSlotList] = useState([]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const logoutHandler = async () => {
    const response = await fetch('/v1/logout');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    localStorage.removeItem('jwt');
    localStorage.removeItem('profilePic');
    setIsLoggedIn(false);
    setLoggedInUserName('')
    setLoggedInUserProfilePic('')
  };

  useEffect(() => {
    const fetchSlots = async () => {
      const date = selectedDate.toISOString().split('T')[0];
      const response = await fetch(`/v1/slots/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({date}),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setIsLoggedIn(data.isLoggedIn);
      setLoggedInUserName(data.loggedInUserName)
      setLoggedInUserProfilePic(data.loggedInUserProfilePic)
      setDoctorName(data.doctorName)
      setSlotList(data.slotList);
    }

    fetchSlots();
  }, [userId, selectedDate]);

  return (
    <div>
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header 
        profilePicture={loggedInUserProfilePic} 
        profileName={loggedInUserName}
        isLoggedIn={isLoggedIn} 
        logoutHandler={logoutHandler}
      />

      <div className="flex-1 p-4 mt-20 flex flex-col md:flex-row"> {/* Flex container for side-by-side layout */}
        {/* Left Side - Calendar */}
        <div className="w-full md:w-1/3 p-6 bg-white rounded-lg shadow-md flex flex-col justify-center"> {/* Calendar box */}
          <h1 className="text-2xl font-bold mb-4 text-center">Select Date</h1>
          <div className="mb-4 text-center">
            <DatePicker 
              selected={selectedDate} 
              onChange={handleDateChange} 
              dateFormat="MMMM d, yyyy"
              className="border border-gray-300 rounded-md p-2 w-full"
              inline // This prop makes the calendar always visible
            />
          </div>
        </div>

        {/* Right Side - Appointment Slots */}
        <div className="w-full md:w-2/3 p-6 flex flex-col max-h-[calc(100vh-10rem)] overflow-y-auto"> {/* Takes up 2/3 of the width and scrollable */}
          <h1 className="text-2xl font-bold mb-4 text-center">Available Slots</h1>
          
          {/* Appointment Cards */}
          <div className="flex-grow"> {/* Allows this section to grow and fill available space */}
            {slotList.map((slot, index) => (
              <SlotCard key={index} doctorName={doctorName} {...slot} />
            ))}
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default Slots;
