import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './assets/bookmyslots-logo.png'
import { Helmet } from "react-helmet";
import Home from './page/Home';
import Login from './page/Login';
import Signup from './page/Signup';
import Search from './page/Search';
import Profile from './page/Profile';
import Slots from './page/Slots';
import Otp from './page/Otp';
import ForgetPassword from './page/ForgetPassword';
import ResetPassword from './page/ResetPassword';
import About from './page/About';
import Contact from './page/Contact';

function App() {
    return (
        <Router>
            <Helmet>
                {/* Default title and favicon for all pages */}
                <title>BookMySlots.in - Simplify Doctor Appointments</title>
                <link rel="icon" href={logo} type="image/x-icon" />
                <meta name="description" content="BookMySlots.in helps you book doctor appointments easily." />
                <meta property="og:title" content="BookMySlots.in - Book Doctor Appointments" />
                <meta property="og:description" content="BookMySlots.in helps you book doctor appointments easily." />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://www.bookmyslots.in" />
            </Helmet>
            <div className="app-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<HelmetWrapper title="Login - BookMySlots.in" element={<Login />} />} />
                    <Route path="/signup" element={<HelmetWrapper title="Signup - BookMySlots.in" element={<Signup />} />} />
                    <Route path="/otp" element={<HelmetWrapper title="Enter OTP - BookMySlots.in" element={<Otp />} />} />
                    <Route path="/forget-password" element={<HelmetWrapper title="Forget Password - BookMySlots.in" element={<ForgetPassword />} />} />
                    <Route path="/reset-password/:resetId" element={<HelmetWrapper title="Reset Password - BookMySlots.in" element={<ResetPassword />} />} />
                    <Route path="/search" element={<HelmetWrapper title="Search Doctors - BookMySlots.in" element={<Search />} />} />
                    <Route path="/profile/:userId" element={<HelmetWrapper title="Profile - BookMySlots.in" element={<Profile />} />} />
                    <Route path="/profile" element={<HelmetWrapper title="My Profile - BookMySlots.in" element={<Profile />} />} />
                    <Route path="/slots/:userId" element={<HelmetWrapper title="Available Slots - BookMySlots.in" element={<Slots />} />} />
                    <Route path="/about" element={<HelmetWrapper title="About Us - BookMySlots.in" element={<About />} />} />
                    <Route path="/contact" element={<HelmetWrapper title="Contact Us - BookMySlots.in" element={<Contact />} />} />
                </Routes>
            </div>
        </Router>
    );
}

const HelmetWrapper = ({ title, element }) => (
    <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {element}
    </>
);

export default App;
