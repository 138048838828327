import React, { useEffect, useState } from 'react';
import verifiedIcon from '../assets/verified_icon.png'
import CreateSlotForm from './CreateSlotForm';
import Header from './Header';


const ProfileForm = () => {
  const [userId, setUserId] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [type, setType] = useState('');
  const [verified, setVerified] = useState(false);
  const [aboutMe, setAboutMe] = useState('');
  const [speciality, setSpeciality] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [slots, setSlots] = useState([]);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [error, setError] = useState('');
  const [initialData, setInitialData] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showSpecialityDropdown, setShowSpecialityDropdown] = useState(false);
  const [slotToEdit, setSlotToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); 

  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('/v1/profile');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (!data.isLoggedIn) {
          window.location.href = '/';
          return;
        }
        setUserId(data.userId);
        setProfilePic(data.profilePic);
        setName(data.name);
        setEmail(data.email);
        setGender(data.gender);
        setType(data.type);
        setAboutMe(data.aboutMe);
        setSpeciality(data.speciality);
        setVerified(data.verified)
        setInitialData({
          profilePic: data.profilePic,
          name: data.name,
          email: data.email,
          gender: data.gender,
          type: data.type,
          aboutMe: data.aboutMe,
          speciality: data.speciality,
        });
        setActiveTab(data.type === "doctor" ? 'slots' : 'upcoming');
        setCityList(data.cityList)
        setSlots(data.slots)
        setSpecialityList(data.specialityList)
      } catch (error) {
        window.location.href = '/';
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const hasChanges =
      name !== initialData.name ||
      email !== initialData.email ||
      gender !== initialData.gender ||
      type !== initialData.type ||
      aboutMe !== initialData.aboutMe ||
      speciality !== initialData.speciality ||
      profilePic !== initialData.profilePic;

    setIsSubmitDisabled(!hasChanges);
  }, [name, email, gender, type, aboutMe, speciality, profilePic, initialData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        setProfilePic(base64String);
      };
      reader.readAsDataURL(file);
    } else {
      alert("File size should be less than 2MB");
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const specialityIds = speciality.map((item) => item.id).join(", ");
    const updatedUser = {
      profilePic,
      name,
      email,
      gender,
      type,
      aboutMe,
      speciality: specialityIds,
    };
    const response = await fetch('/v1/update/profile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(updatedUser),
    });

    const data = await response.json();

    if (!response.ok) {
      setMessage('Failed to update the profile. Try again later.');
      setMessageType('error');
    } else {
      setMessage('Profile updated successfully!');
      setMessageType('success');
    }
    setIsLoading(false);

    // Clear the message after 2 seconds
    setTimeout(() => {
      setMessage('');
    }, 2000);

    if (data.profilePic) {
      localStorage.setItem('profilePic', data.profilePic);
      updatedUser.profilePic = data.profilePic;
    }
    setProfilePic(data.profilePic);
    setName(data.name);
    setEmail(data.email);
    setGender(data.gender);
    setType(data.type);
    setVerified(data.verified)
    setAboutMe(data.aboutMe);
    setInitialData({
      profilePic: data.profilePic,
      name: data.name,
      email: data.email,
      gender: data.gender,
      type: data.type,
      aboutMe: data.aboutMe,
      speciality: speciality,
      slots: slots,
    });
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        const response = await fetch(`/v1/delete-user/${userId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          alert(`Failed to delete account: ${errorData.error}`);
          return;
        }
  
        alert('Your account has been successfully deleted.');
        window.location.href = '/';
      } catch (error) {
        alert('An unexpected error occurred. Please try again later.');
      }
    }
  };
  

  const logoutHandler = async () => {
    const response = await fetch('/v1/logout');
    const data = response.json();
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    localStorage.removeItem('jwt');
    localStorage.removeItem('profilePic');

    window.location.href = '/';
  };

  const handleClosePopup = () => {
    setError('');
    setSlotToEdit(null);
    setIsPopupOpen(false);
  };

  const handleFormSubmit = async (slotFormData) => {
    const response = await fetch('/v1/slot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(slotFormData),
    });
  
    const data = await response.json();
    if (!response.ok) {
      setError('Failed to create slot, try after some time')
    } else {
      const city = cityList.find(city => city.id === data?.cityId)?.name;
      const newSlot = {
        id:           data.id,
        name:         data.name,
        mobileNumber: data.mobileNumber,
        address:      data.address,
        locationUrl:  data.locationURL,
        pincode:      data.pincode,
        cityId:       data.cityID,
        city:         city,
        schedule:     data.schedule,
        startTime:    data.startTime,
        endTime:      data.endTime,
      };
    
      setSlots((prevSlotList) => [...prevSlotList, newSlot]);
      handleClosePopup();
    }
  };
  

  const handleFormEdit = async (slotFormData) => {
    const response = await fetch('/v1/update-slot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(slotFormData),
    });
  
    const data = await response.json();
    if (!response.ok) {
      setError('Failed to update slot, try after some time')
    } else {
      const updatedCity = cityList.find(city => city.id === slotFormData.city)?.name;
    
      if (updatedCity) {
        slotFormData.cityId = slotFormData.city;
        slotFormData.city = updatedCity;
      }

      setSlots((prevSlotList) => {
        return prevSlotList.map((slot) =>
          slot.id === slotFormData.id ? { ...slot, ...slotFormData } : slot
        );
      });
      handleClosePopup();
    }
  };

  // Toggle popup
  const toggleSlotPopup = () => setIsPopupOpen(!isPopupOpen);

  const handleSpecialitySelect = (specialityItem) => {
    // Check if the speciality is already selected
    if (!speciality.some((item) => item.id === specialityItem.id)) {
      const updatedSpecialities = [...speciality, specialityItem];
      setSpeciality(updatedSpecialities);
    }
  };
  
  // Helper function to remove a selected speciality (optional feature)
  const handleRemoveSpeciality = (specialityId) => {
    const updatedSpecialities = speciality.filter((item) => item.id !== specialityId);
    setSpeciality(updatedSpecialities);
  };

  const handleEditSlot = (slotId) => {
    const slot = slots.find((slot) => slot.id === slotId);
    if (slot) {
      setSlotToEdit(slot); // Pass the selected slot data
      setIsPopupOpen(true); // Open the popup
    }
  };
  
  const handleDeleteSlot = async (slotId) => {
    if (window.confirm('Are you sure you want to delete this slot?')) {
      try {
        const response = await fetch(`/v1/delete-slot/${slotId}`, { method: 'DELETE' });
        if (response.ok) {
          setSlots((prevSlots) => prevSlots.filter((slot) => slot.id !== slotId));
        } else {
          alert('Failed to delete slot.');
        }
      } catch (error) {
        alert('Failed to delete slot.');
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full mb-1 h-screen overflow-auto pt-[80px]">
      <Header
        profileName={name}
        profilePicture={profilePic}
        isLoggedIn={true}
        logoutHandler={logoutHandler}
      />

      {isPopupOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-blur-sm z-40"></div>
      )}

      {isPopupOpen && (
        <CreateSlotForm
          onClose={handleClosePopup}
          onSubmit={handleFormSubmit}
          onEdit={handleFormEdit}
          cityList={cityList}
          slotData={slotToEdit}
          error={error}
        />
      )}
      <div className="flex flex-col md:flex-row flex-grow">
        <div className="w-full md:w-1/3 p-6 bg-white flex flex-col items-center justify-center border-b md:border-r md:border-b-0 relative">
          {/* Profile Form */}
          <form
            onSubmit={handleSubmit}
            className="w-full max-w-md flex flex-col items-center"
          >
            {/* Verified Badge with Tooltip */}
            {(type === 'doctor' && verified) && (
              <div className="absolute top-2 right-2 group">
                <img
                  src={verifiedIcon}
                  alt="Verified Icon"
                  className="w-6 h-6"
                />
                {/* Tooltip */}
                <div className="absolute top-full right-0 mt-1 hidden group-hover:block bg-black text-white text-xs rounded-md py-1 px-2 shadow-lg">
                  Verified by BookMySlots.in
                </div>
              </div>
            )}

            {/* Compact Verification Banner */}
            {(type === 'doctor' && !verified) && (
              <div className="w-full bg-yellow-100 text-yellow-800 text-sm text-center p-2 rounded mb-2">
                Send a picture of a your blank prescription to 
                <span className="font-bold"> operation.bookmyslots@gmail.com </span>
                from your registered email to get verified.
              </div>
            )}
            {/* Profile Picture */}
            <div className="mb-6 flex flex-col items-center">
              <img
                src={`data:image/jpeg;base64,${profilePic}`}
                alt="Profile"
                className="w-32 h-32 rounded-full border-2 border-gray-300 mb-2"
              />
              <label className="cursor-pointer text-blue-500 hover:underline">
                Edit Profile Picture
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                />
              </label>
            </div>
            <div className="flex items-center mb-4 w-full">
              <label htmlFor="name" className="w-1/3 text-right pr-4 font-semibold">Name:</label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                className="p-2 border border-gray-300 rounded w-2/3"
              />
            </div>
            <div className="flex items-center mb-4 w-full">
              <label htmlFor="email" className="w-1/3 text-right pr-4 font-semibold">Email:</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="p-2 border border-gray-300 rounded w-2/3"
              />
            </div>
            {initialData.type === 'doctor' && (
              <div className="flex items-start mb-4 w-full">
                <label htmlFor="aboutMe" className="w-1/3 text-right pr-4 font-semibold">About Me:</label>
                <textarea
                  id="aboutMe"
                  value={aboutMe}
                  onChange={(e) => setAboutMe(e.target.value)}
                  placeholder="About Me"
                  className="p-2 border border-gray-300 rounded w-2/3 h-24 resize-none"
                />
              </div>
            )}
            {initialData.type === 'doctor' && (
              <div className="flex items-center mb-4 w-full relative">
                <label htmlFor="speciality" className="w-1/3 text-right pr-4 font-semibold">
                  Specialty:
                </label>
                {/* Specialty Input Box */}
                <div className="w-2/3 relative">
                  <div className="flex items-center">
                    {/* Display selected specialties vertically inside the input area */}
                    <textarea
                      id="speciality"
                      value={speciality.map((item) => `• ${item.name}`).join("\n")} // Add "• " before each specialty name
                      readOnly
                      placeholder="Select specialties"
                      className="p-2 border border-gray-300 rounded w-full h-24 resize-none text-sm overflow-y-auto" // Make text smaller and allow overflow
                    />
                    {/* Plus or X icon to toggle the dropdown */}
                    <button
                      type="button"
                      onClick={() => setShowSpecialityDropdown(!showSpecialityDropdown)}
                      className="ml-2 p-2 bg-gray-200 rounded hover:bg-gray-300 transition h-10 w-12"
                    >
                      {showSpecialityDropdown ? 'x' : '+'}
                    </button>
                  </div>
                  
                  {/* Dropdown list */}
                  {showSpecialityDropdown && (
                    <div className="absolute z-10 bg-white border border-gray-300 rounded shadow-md mt-1 w-full max-h-40 overflow-y-auto">
                      <ul>
                        {specialityList.map((specialityItem) => (
                          <li
                            key={specialityItem.id}
                            className="flex items-center p-2 cursor-pointer hover:bg-blue-100"
                          >
                            <input
                              type="checkbox"
                              id={`speciality-${specialityItem.id}`}
                              checked={speciality.some((item) => item.id === specialityItem.id)}
                              onChange={() => {
                                if (speciality.some((item) => item.id === specialityItem.id)) {
                                  handleRemoveSpeciality(specialityItem.id);
                                } else {
                                  handleSpecialitySelect(specialityItem);
                                }
                              }}
                              className="mr-2"
                            />
                            <label htmlFor={`speciality-${specialityItem.id}`} className="cursor-pointer">
                              {specialityItem.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="mb-4 flex items-center w-full">
              <label className="w-1/3 text-right pr-4 font-semibold">Gender:</label>
              <div className="flex space-x-4 w-2/3">
                <button
                  type="button"
                  className={`p-1.5 text-sm border ${
                    gender === 'male' ? 'bg-green-500 text-white' : 'bg-gray-100'
                  } rounded w-20`}
                  onClick={() => setGender('male')}
                >
                  Male
                </button>
                <button
                  type="button"
                  className={`p-1.5 text-sm border ${
                    gender === 'female' ? 'bg-green-500 text-white' : 'bg-gray-100'
                  } rounded w-20`}
                  onClick={() => setGender('female')}
                >
                  Female
                </button>
              </div>
            </div>
            <div className="mb-4 flex items-center w-full">
              <label className="w-1/3 text-right pr-4 font-semibold">I am a:</label>
              <div className="flex space-x-4 w-2/3">
                <button
                  type="button"
                  className={`p-1.5 text-sm border ${
                    type === 'user' ? 'bg-green-500 text-white' : 'bg-gray-100'
                  } rounded w-20`}
                  onClick={() => setType('user')}
                >
                  User
                </button>
                <button
                  type="button"
                  className={`p-1.5 text-sm border ${
                    type === 'doctor' ? 'bg-green-500 text-white' : 'bg-gray-100'
                  } rounded w-20`}
                  onClick={() => setType('doctor')}
                >
                  Doctor
                </button>
              </div>
            </div>
            {/* Show success or error message */}
            {message && (
              <div className={`text-sm mt-2 mb-1 ${messageType === 'success' ? 'text-green-500' : 'text-red-500'}`}>
                {message}
              </div>
            )}
            <button
              type="submit"
              disabled={isSubmitDisabled || isLoading}
              className={`p-2 mb-4 rounded w-full transition duration-200 ${isSubmitDisabled || isLoading ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <svg className="w-5 h-5 mr-2 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 1 1 8 8 8 8 0 0 1-8-8z"></path>
                  </svg>
                  Loading...
                </div>
              ) : 'Update Profile'}
            </button>
            {initialData.type === "doctor" ? (<button
              type="button"
              onClick={toggleSlotPopup}
              className="p-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200 w-full"
            >
              Create a new Slot
            </button>) : ''}
            <button
              type="button"
              onClick={handleDeleteAccount}
              className="p-2 mb-4 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200 w-full"
            >
              Delete Account
            </button>
          </form>
        </div>
        
        {/* Right side */}
        <div className="w-full md:w-2/3 p-6 h-full overflow-auto bg-gray-50">
          <div className="mb-4 flex space-x-6">
            {initialData?.type === 'doctor' && (
              <button
                onClick={() => setActiveTab('slots')}
                className={`p-2 ${activeTab === 'slots' ? 'border-b-2 border-blue-500' : ''}`}
              >
                Slots
              </button>
            )}
            <button
              onClick={() => setActiveTab('upcoming')}
              className={`p-2 ${activeTab === 'upcoming' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Upcoming Bookings
            </button>
            <button
              onClick={() => setActiveTab('past')}
              className={`p-2 ${activeTab === 'past' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Past Bookings
            </button>
          </div>
          <div className="p-4 border border-gray-300 rounded">
            {activeTab === 'slots' ? (
              <div>
                <h2 className="text-xl font-semibold mb-4">Active Slots</h2>
                {slots && slots.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {slots.map((slot) => (
                      <div
                        key={slot.id}
                        className="p-4 border border-gray-300 rounded shadow-lg bg-white hover:shadow-xl transition"
                      >
                        <h2 className="font-semibold text-lg text-center mb-4">{slot.name}</h2>
                        <div className="text-sm">
                          <div className="flex items-center">
                            <label htmlFor="mobileNumber" className="font-semibold">Mobile Number:</label>
                            <div className="ml-2">
                              <span id="mobileNumber" className="">
                                {slot?.mobileNumber}
                              </span>
                            </div>
                          </div>
                        </div>
                        <p className="text-sm">
                          <span className="font-bold mr-2">Address:</span> {slot.address}
                        </p>
                        <p className="text-sm">
                          <span className="font-bold mr-2">City:</span> {slot.city}
                        </p>
                        <p className="text-sm">
                          <span className="font-bold mr-2">Pincode:</span> {slot.pincode}
                        </p>
                        <p className="text-sm">
                          <span className="font-bold mr-2">Time:</span> {slot.startTime} - {slot.endTime}
                        </p>
                        <p className="text-sm">
                          <span className="font-bold mr-2">Schedule:</span> {slot.schedule}
                        </p>
                        {slot.locationUrl && (
                          <p className="text-sm">
                            <span className="font-bold mr-2">Location: </span>
                            <a
                              href={slot.locationUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 text-sm underline"
                            >
                              View Location
                            </a>
                          </p>
                        )}
                        <div className="flex justify-end space-x-2 mt-4">
                          <button
                            onClick={() => handleEditSlot(slot.id)}
                            className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600 transition"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeleteSlot(slot.id)}
                            className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600 transition"
                          >
                            Delete
                          </button>
                        </div>
                      </div>                    
                    ))}
                  </div>
                ) : (
                  <p>No slots available.</p>
                )}
              </div>
            ) : activeTab === 'upcoming' ? (
              <div>
                <h2 className="text-xl font-semibold mb-2">Upcoming Bookings</h2>
                <p>No upcoming bookings.</p>
              </div>
            ) : (
              <div>
                <h2 className="text-xl font-semibold mb-2">Past Bookings</h2>
                <p>No past bookings.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
