import { useParams } from 'react-router-dom';
import ProfileForm from "../components/ProfileForm";
import ProfileCard from '../components/ProfileCard';
import Footer from '../components/Footer';

const Profile = () => {
  const { userId } = useParams();
  
  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full">
        <div className="w-full"> {/* Adjust max-w as needed */}
          {userId ? (<ProfileCard userId={userId} />) : (<ProfileForm />)}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
