import React, { useState } from 'react';
import Footer from '../components/Footer';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    setError('');
    setIsLoading(true);

    try {
      const response = await fetch('/v1/authn/send-reset-link', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
        credentials: 'include', // Include cookies in the request if needed
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'Failed to send reset link');
        return;
      }

      setSuccess('Reset link sent successfully! Check your email.');
    } catch (err) {
      setError(err.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
      setEmail(''); // Clear email input
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <form
          className="flex flex-col bg-white p-6 rounded shadow-md w-full max-w-sm"
          onSubmit={onSubmitHandler}
        >
          <p className="mb-4 text-sm text-gray-600 text-center">
            Enter the email address you use to sign in to <span className="font-semibold text-gray-800">BookMySlots.in</span>
          </p>
          <h2 className="mb-4 text-xl font-semibold text-center">Forgot Password</h2>

          {error && (
            <div className="flex justify-center mb-4">
              <p className="text-red-500">{error}</p>
            </div>
          )}
          {success && (
            <div className="flex justify-center mb-4">
              <p className="text-green-500">{success}</p>
            </div>
          )}

          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-4 p-2 border border-gray-300 rounded"
            required
          />

          <button
            type="submit"
            disabled={isLoading}
            className={`p-2 rounded transition duration-200 ${
              isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <svg
                  className="w-4 h-4 mr-2 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                  ></path>
                </svg>
                Sending...
              </span>
            ) : (
              'Send Reset Link'
            )}
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ForgetPassword;
