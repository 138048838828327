import React from 'react';
import verifiedIcon from '../assets/verified_icon.png';

const ItemCard = ({ id, profilePic, name, speciality, gender, rating, verified }) => {
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <span className="text-yellow-500 text-xs">
        {'★'.repeat(fullStars)}
        {'✩'.repeat(halfStars)}
        {'☆'.repeat(emptyStars)}
      </span>
    );
  };

  const handleProfileClick = () => {
    window.open(`/profile/${id}`, '_blank');
  };

  const handleSlotBooking = () => {
    window.open(`/slots/${id}`, '_blank');
  };

  const specialityList = speciality && speciality.trim() !== '' 
    ? speciality.split(',').map((item) => item.trim()) 
    : [];

  return (
    <div className="relative flex flex-col border border-gray-300 rounded-lg shadow-lg p-4 bg-white w-full max-w-sm h-auto transition-transform transform hover:scale-105">
      {/* Verified Badge */}
      {verified && (
        <div className="absolute top-1.5 right-1.5">
          <div className="group relative">
            {/* Verified Badge Icon */}
            <img
              src={verifiedIcon}
              alt="Verified Badge"
              className="w-5 h-5"
              title="Verified by BookMySlots.in"
            />
            {/* Tooltip */}
            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-black text-white text-xs rounded-lg py-1 px-2 z-10">
              Verified by BookMySlots.in
            </div>
          </div>
        </div>
      )}

      <div className="flex items-start mb-4">
        {/* Left Side: Profile Picture and Rating */}
        <div className="flex-shrink-0 flex flex-col items-center mr-4">
          <img
            src={`data:image/jpeg;base64,${profilePic}`}
            alt={`${name}'s profile`}
            className="w-24 h-24 rounded-full border-2 border-gray-300 mb-2"
          />
          {/* <div className="flex items-center">
            {renderStars(rating)}
            <span className="ml-1 text-gray-600 text-xs">({rating.toFixed(1)})</span>
          </div> */}
        </div>

        {/* Right Side: Profile Details */}
        <div className="flex-grow flex flex-col justify-between ml-2">
          <h2 className="text-lg font-semibold">Dr. {name}</h2>
          
          {/* Gender */}
          <p className="text-sm text-gray-600">
            <span className="font-bold">Gender:</span> {gender === "male" ? "Male" : "Female"}
          </p>

          {/* Specialities as Dotted List */}
          <p className="text-sm text-gray-600">
            <span className="font-bold">Specialities:</span>
            {(specialityList && specialityList.length > 0) && (
              <ul className="list-inside list-disc text-gray-600 mt-1 pl-4">
                {specialityList.map((spec, index) => (
                  <li key={index} className="text-sm">{spec}</li>
                ))}
              </ul>
            )}
          </p>
        </div>
      </div>

      {/* Buttons covering the full bottom of the card */}
      <div className="flex mt-auto space-x-2">
        <button
          onClick={handleProfileClick}
          className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 transition duration-200 w-full flex items-center justify-center shadow-md transform transition-transform duration-150 ease-in-out active:scale-95">
          View Profile
          <span className="ml-2">→</span>
        </button>
        <button 
          onClick={handleSlotBooking}
          className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600 transition duration-200 w-full flex items-center justify-center shadow-md transform transition-transform duration-150 ease-in-out active:scale-95">
          Book an Appointment
          <span className="ml-2">→</span>
        </button>
      </div>
    </div>
  );
};

export default ItemCard;
