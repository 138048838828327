import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa'; // Importing the magnifying glass icon

const SearchBar = ({ searchHandler }) => {
  const [query, setQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    searchHandler(query);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e); // Trigger search on Enter key press
    }
  };

  return (
    <form 
      onSubmit={handleSearch} 
      className="flex flex-col md:flex-row items-center justify-between bg-white p-4 rounded-lg shadow-lg w-full max-w-2xl transition-transform transform hover:scale-105 hover:shadow-xl"
    >
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type your search query..."
        className="border border-gray-300 rounded-lg p-4 flex-grow text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2 md:mb-0 md:mr-2 w-full md:w-auto" // Full width on small screens and auto width on larger screens
      />
      
      <button
        type="submit"
        className="bg-blue-500 text-white p-2 rounded-lg flex items-center justify-center hover:bg-blue-600 transition duration-200 text-lg h-full md:h-auto shadow-md transform transition-transform duration-150 ease-in-out active:scale-95 mt-2 md:mt-0 md:p-3">
        <FaSearch className="h-5 w-5" />
      </button>
    </form>
  );
};

export default SearchBar;
