import React, { useState, useEffect } from "react";
import { FaSearch } from 'react-icons/fa';
import Header from "../components/Header";
import logo from '../assets/bookmyslots-logo.png';
import Footer from "../components/Footer";

const Home = () => {
  const [profileDetails, setProfileDetails] = useState({
    profileId: "",
    profilePicture: "",
    profileName: "",
    profileEmail: "",
    isLoggedIn: false
  });
  const [typedText, setTypedText] = useState('');
  const fullText = 'Find the right doctor and book appointments easily.';

  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = '/search';
  }

  const handleLogout = async () => {
    const response = await fetch('/v1/logout');
    const data = await response.json();
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    localStorage.removeItem('jwt');
    localStorage.removeItem('profilePic');

    setProfileDetails({
      profileId: '',
      profilePicture: '',
      profileName: '',
      profileEmail: '',
      isLoggedIn: false
    });
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch('/v1/profile');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setProfileDetails({
          profileId: data.userId,
          profilePicture: data.profilePic,
          profileName: data.name,
          profileEmail: data.email,
          isLoggedIn: data.isLoggedIn
        });
      } catch (error) {
        throw new Error('Error fetching profile data')
      }
    };

    fetchProfileData();
    setTypedText('');
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (typedText.length < fullText.length) {
        setTypedText(typedText + fullText.charAt(typedText.length));
      } else {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [typedText]);

  return (
    <div className="home-container flex flex-col min-h-screen">
      {/* Main content */}
      <div className="flex-grow flex flex-col justify-center items-center">
        <Header 
          profilePicture={profileDetails.profilePicture} 
          profileName={profileDetails.profileName} 
          isLoggedIn={profileDetails.isLoggedIn} 
          logoutHandler={handleLogout}
        />
        <img src={logo} alt="Bookmyslots Logo" className="mb-4 w-32 h-auto" /> {/* Logo added */}
        <p className="text-xl text-center">Welcome to Bookmyslots.in</p>
        <p className="text-2xl font-bold text-center mb-4">
          {typedText}
        </p>
        <button
          onClick={handleClick}
          className="bg-blue-500 text-white p-3 rounded-lg flex items-center justify-center hover:bg-blue-600 transition duration-200 shadow-md transform transition-transform duration-150 ease-in-out active:scale-95 cursor-pointer">
          <span className="text-lg mr-2">Click me to Search</span>
          <FaSearch className="h-6 w-6" />
        </button>
      </div>
      
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
